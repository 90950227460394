import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PwEnumToReadableStringPipe,
  PwNullableValuePipe,
} from '@pwiz/infra/ui';
import { ISprint } from '@pwiz/entity/ts';
import { getSprintUiStatus } from '@pwiz/sprint/ts';

@Component({
  selector: 'pw-sprint-status',
  standalone: true,
  imports: [CommonModule, PwEnumToReadableStringPipe, PwNullableValuePipe],
  styles: `
    :host {
      display: inline-block;
      font-size: 1rem;
      font-weight: bold;
    }
  `,
  template: `{{ $status() | pwEnumToReadableString }}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SprintStatusComponent {
  sprint = input.required<Omit<ISprint, 'ticketList' | 'itemList'>>();
  $status = computed(() => getSprintUiStatus(this.sprint()));
}
