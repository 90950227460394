import { DrawerRoutingService } from '@pwiz/infra/drawer';
import { SprintDetails } from '@pwiz/sprint/ts';
import { inject } from '@angular/core';

export function useOpenSprintSettings() {
  const drawerRouting = inject(DrawerRoutingService);
  return (teamIds: string[] | null, sprint: SprintDetails | null) =>
    drawerRouting.sprintSettings({
      teamList: teamIds || undefined,
      sprintId: sprint?.id,
    });
}
